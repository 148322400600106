import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	base: '',
	routes,
	scrollBehavior (to, from, savedPosition) {
		const layoutId = document.getElementById('page');
		if (layoutId) {
			layoutId.scrollTop = 0;	
		}
	}
})

export default router
