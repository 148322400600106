import Axios from 'axios'

const $axios = Axios.create({
	baseURL: `${process.env.VUE_APP_API_ROUTE}/`,
})

const sanitizeMenu = (menu, locale = 'pt-br') => {
	const translation = menu.translations.find(item => item.locale == locale) || menu.translations[0]
	return {
		id: menu.id,
		name: translation.name,
		type: menu.type,
		items: menu.items ? menu.items.map(item => sanitizeMenuItem(item, locale)) : []
	}
}

const sanitizeMenuItem = (menu, locale) => {
	const translation = menu.translations.find(item => item.locale == locale) || menu.translations[0]
	const page = menu.page ? menu.page.translations.find(item => item.locale == locale) || menu.page.translations[0] : null
	const subcategory = menu.page ? menu.page.category.translations.find(item => item.locale == locale).slug || menu.page.category.translations[0].slug : null
	const category = menu.page && menu.page.category.category ? menu.page.category.category.translations.find(item => item.locale == locale).slug || menu.page.category.translations[0].slug : null
	let route = { 
		external: false,
	}
	if (menu.type != 'category') {
		route.link = {
			name: `Pagina`,
			params: {
				category: category || subcategory, 
				subcategory: category ? subcategory : null, 
				slug: page ? page.slug : null, 
				locale,
			}
		}
	}
	if (menu.type == 'redirect') {
		route = {
			external: true,
			link: translation.link,
			newTab: [null, undefined].includes(menu.isNewTab) ? true : menu.isNewTab
		}
	}
	if (menu.type === 'file') {
		route = {
			external: true,
			link: menu.file.filePath,
			newTab: true
		}
	}
	return {
		name: translation.name,
		type: menu.type,
		route,
		items: menu.menuItems ? menu.menuItems.map(item => sanitizeMenuItem(item, locale, category)) : []
	}
}

export default {
	namespaced: true,
	state: () => ({
		header: {},
		footer: {},
		pagina: {},
	}),
	getters: {
		header(state, getters, rootState) {
			if (!state.header.id)
				return {}
			const header = sanitizeMenu(state.header, rootState.locale || 'pt-br')
			return {
				...header,
				items: header.items.filter(item => item.type != 'category')
			}
		},
		footer(state, getters, rootState) {
			if (!state.footer.id)
				return {}
			return sanitizeMenu(state.footer, rootState.locale || 'pt-br')
		},
		pagina(state, getters, rootState) {
			if (!state.pagina.id)
				return {}
			return sanitizeMenu(state.pagina, rootState.locale || 'pt-br')
		},
	},
	mutations: {
		setHeader(state, payload) {
			state.header = { ...payload }
		},
		setFooter(state, payload) {
			state.footer = { ...payload }
		},
		setPagina(state, payload) {
			state.pagina = { ...payload }
		}
	},
	actions: {
		async getHeader({ commit }) {
			const response = await $axios.get(`menus/type/header`)
			if (response.status == 200) {
				commit('setHeader', response.data.menu)
			}
		},
		async getFooter({ commit }) {
			const response = await $axios.get(`menus/type/footer`)
			if (response.status == 200) {
				commit('setFooter', response.data.menu)
			}
		},
		async getPagina({ commit }, id) {
			const response = await $axios.get(`menus/${id}`)
			if (response.status == 200) {
				commit('setPagina', response.data.menu)
			}
		}
	}
}
